import { slugs } from '@/constants'

export const bannerImages = [
  { projectSlug: slugs.theChosen, bannerUrl: 'v1698873816/angel-studios/Chosen.png' },
  { projectSlug: slugs.freelancers, bannerUrl: 'v1698875839/angel-studios/Freelancer.png' },
  { projectSlug: slugs.wingfeatherSaga, bannerUrl: 'v1698876098/angel-studios/Wing.png' },
  { projectSlug: slugs.tuttleTwins, bannerUrl: 'v1698876156/angel-studios/Tuttle_Twins.png' },
  { projectSlug: slugs.dryBar, bannerUrl: 'v1698876254/angel-studios/DryBar.png' },
  { projectSlug: slugs.jungleBeat, bannerUrl: 'v1698876262/angel-studios/JungleBeat.png' },
  { projectSlug: slugs.testament, bannerUrl: 'v1698876290/angel-studios/Testament.png' },
  { projectSlug: slugs.playingForEternity, bannerUrl: 'v1698876393/angel-studios/PFE.png' },
  { projectSlug: slugs.riot, bannerUrl: 'v1698876415/angel-studios/Riot.png' },
  { projectSlug: slugs.youngDavid, bannerUrl: 'v1699037409/angel-studios/Young_David.png' },
  { projectSlug: slugs.soundOfFreedom, bannerUrl: 'v1698875933/angel-studios/SOF.png' },
  { projectSlug: slugs.hisOnlySon, bannerUrl: 'v1698875959/angel-studios/HOS.png' },
  { projectSlug: slugs.gabrielAndTheGuardians, bannerUrl: 'v1698876077/angel-studios/GATG.png' },
  { projectSlug: slugs.theShift, bannerUrl: 'v1698876192/angel-studios/Shift.png' },
  { projectSlug: slugs.afterDeath, bannerUrl: 'v1698876349/angel-studios/After_Death.png' },
  { projectSlug: slugs.cabrini, bannerUrl: 'v1698876373/angel-studios/Cabrini.png' },
  { projectSlug: slugs.truthAndConviction, bannerUrl: 'v1698876448/angel-studios/TruthC.png' },
  { projectSlug: slugs.truthAndTreason, bannerUrl: 'v1698876448/angel-studios/TruthC.png' },
  { projectSlug: slugs.liveNotByLies, bannerUrl: 'v1698876462/angel-studios/LNBL.png' },
  { projectSlug: slugs.david, bannerUrl: 'v1698875910/angel-studios/David.png' },
]
